<template>
	<div class="wrap">
		<!-- 本站 -->
		<div class="top">
			<div class="top1">
				<div class="top1-left">
					<div>
						<span>当前时间</span>
						<span class="left-number">{{time}}</span>
					</div>
					<div>
						<span>最大载客量</span>
						<span class="left-number">{{maxNumber}}</span>
					</div>
				</div>
				<div class="top1-right">
					<span>
						【本站】{{config.companyName}}
					</span>
					<span>
						{{self.projectName}}
					</span>
					<span style="margin-left: 0.1rem;">
						{{selfAdd||self.projectRemark}}
					</span>
				</div>
			</div>
			<div class="top2" :style="{color:self.color}">
				{{self.autoTitle}}
			</div>
			<div class="top3">
				{{self.showStart}}<span>—</span>{{self.showEnd}}
			</div>
			<div class="top4">
				<!-- 排号和时间都不显示 -->
				<template v-if="!config2.showNumberTo&&!config2.showEntryTime">
					<div style="font-size: 0.64rem;width: 100%;">
						<span>今日余票</span>
						<span style="font-size:0.92rem;">{{self.batchHasNum}}</span>
					</div>
				</template>
				<!-- 排号和时间显示一个 -->
				<template v-if="config2.showNumberTo!=config2.showEntryTime">
					<div style="width:50%;justify-content: flex-start;" v-if="config2.showNumberTo=='1'">
						<span>已排号至</span>
						<span>{{self.sortTotalCapacity}}</span>
					</div>
					<div style="width:50%;justify-content: flex-start;" v-if="config2.showEntryTime=='1'">
						<span>预计排队进入时间</span>
						<span>{{self.sortTotalTime}}</span>
					</div>
					<div style="width: 50%;justify-content: flex-end;">
						<span>今日余票</span>
						<span>{{self.batchHasNum}}</span>
					</div>
				</template>
				<!-- 排号和时间都显示 -->
				<template v-if="config2.showNumberTo=='1'&&config2.showEntryTime=='1'">
					<div style="width: 30%;padding-left: 0.2rem;display: flex;justify-content: flex-start;">
						<span>已排号至</span>
						<span>{{self.sortTotalCapacity}}</span>
					</div>
					<div style="width: 40%;">
						<span>预计排队进入时间</span>
						<span>{{self.sortTotalTime}}</span>
					</div>
					<div style="width: 30%;padding-right: 0.2rem;display: flex;justify-content: flex-end;">
						<span>今日余票</span>
						<span>{{self.batchHasNum}}</span>
					</div>
				</template>
			</div>
		</div>
		
		<div class="middle">
			<div>
				<marquee scrollamount="7" style="height: 0.6rem;font-size: 0.4rem;" v-html="screenTip">
					
				</marquee>
			</div>
			
		</div>
		<!-- 对岸 -->
		<div class="bottom">
			<div class="bottom1">
				<!-- 排号和时间只显示一个，或者都显示 -->
				<div class="bottom1-left" :style="{textAlign:config2.showNumberTo=='1'||config2.showEntryTime=='1'?'left':'center'}">
					<span>
						【对岸】{{config.companyName}}
					</span>
					<span>
						{{other.projectName}}
					</span>
					<span style="margin-left: 0.1rem;">
						{{otherAdd||other.projectRemark}}
					</span>
				</div>
				<!-- 排号和时间只显示一个，或者都显示 -->
				<div class="bottom1-right" v-if="config2.showNumberTo=='1'||config2.showEntryTime=='1'">
					<span :style="{color:other.color}">{{other.autoTitle}}</span>
					<span>{{other.showStart}}-{{other.showEnd}}</span>
				</div>
			</div>
			
			<div class="bottom2">
				<!--排号和时间都不显示 -->
				<template v-if="!config2.showNumberTo&&!config2.showEntryTime">
					<div style="width:50%;">
						<span :style="{color:other.color}">{{other.autoTitle}}</span>
						<span>{{other.showStart}}-{{other.showEnd}}</span>
					</div>
					<div style="width: 50%;">
						<span>今日余票</span>
						<span>{{other.batchHasNum}}</span>
					</div>
				</template>
				<!-- 排号和时间显示一个-->
				<template v-if="config2.showNumberTo!=config2.showEntryTime">
					<div style="width:50%;justify-content: flex-start;" v-if="config2.showNumberTo=='1'">
						<span>已排号至</span>
						<span>{{other.sortTotalCapacity}}</span>
					</div>
					<div style="width:50%;justify-content: flex-start;" v-if="config2.showEntryTime=='1'">
						<span>预计排队进入时间</span>
						<span>{{other.sortTotalTime}}</span>
					</div>
					<div style="width: 50%;justify-content: flex-end;">
						<span>今日余票</span>
						<span>{{other.batchHasNum}}</span>
					</div>
				</template>
				<!-- 排号和时间都显示 -->
				<template v-if="config2.showNumberTo=='1'&&config2.showEntryTime=='1'">
					<div style="width: 30%;padding-left: 0.2rem;display: flex;justify-content: flex-start;">
						<span>已排号至</span>
						<span>{{other.sortTotalCapacity}}</span>
					</div>
					<div style="width: 40%;">
						<span>预计排队进入时间</span>
						<span>{{other.sortTotalTime}}</span>
					</div>
					<div style="width: 30%;padding-right: 0.2rem;display: flex;justify-content: flex-end;">
						<span>今日余票</span>
						<span>{{other.batchHasNum}}</span>
					</div>
				</template>
			</div>
			
			<div class="bottom3" v-html="screenBottomTip">
			</div>
			<div class="bottom4">
				<span style="font-size: 12px;color: #cccccc;" v-if='showStatus'>设备编号:{{deviceKey}}</span>
				<span style="color: #ff0000;font-size: 16px;" v-if='showStatus'>状态：{{errMsg}}</span>
				<span style="text-align: right;flex: 1;">
					<img src="../assets/image/tbdLogo.png" style="height:18px;"/>
					{{versionName}}
				</span>
			</div>
			
		</div>
		<!--扫码购票-->
		<div class="scan-code" v-if="config2.showScanningCode=='1'">
			<div class="scan-img">
				<div>
					<img src="../assets/image/scanCode.png"/>
				</div>
				<div style="margin-top: 0.6rem;font-size: 0.4rem;">
					扫码购票
				</div>
			</div>
			
			<div class="scan-introduce">
				<div style="color: #ff0000;font-size: 0.48rem;font-weight: bold;">
					购票说明：
				</div>
				<div class="scan-phone">
					<div>
						1.扫描上方二维码，实名预约购票；
					</div>
					<div>
						2.景区咨询电话：023-68816888。
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
import { ref,reactive,onBeforeUnmount} from 'vue'
import {useRoute } from 'vue-router'
import $request from '../assets/js/request.js'
let route = useRoute()
//是否显示取号信息，默认游客不展示，管理员展示
let errMsg=ref('')
let maxNumber=ref(0)//最大购买数量
let time=ref('') //倒计时
let screenTip=ref('')//配置信息
let self=ref({})//本站点信息
let other=ref({})//其他站点信息
let selfAdd=ref('')//本站地址
let otherAdd=ref('')//对面地址
let screenBottomTip=ref('')//底部文字
let deviceKey=route.query.hardKey||route.query.deviceKey||''
let showStatus=false
let config=''
let config2=''
let token=''
let versionName=localStorage.versionName
if(sessionStorage.config){
  token=JSON.parse(sessionStorage.config).token
  config=JSON.parse(sessionStorage.config)//登录缓存的本地信息
  config2=config.config
  if(config2.showEntryTime){
    config2.showEntryTime=parseInt(config2.showEntryTime)
  }
  if(config2.showNumberTo){
    config2.showNumberTo=parseInt(config2.showNumberTo)
  }
  if(config2.showScanningCode){
    config2.showScanningCode=parseInt(config2.showScanningCode)
  }
}
let timer=''
//---获取排队信息
let data={
  companyId:config.companyId
}
function getSort(){
  $request('tbd-product/sort/scene/batchQuery?token='+token, data).then((res) => {
		  if (res.code == '00') {
      showStatus=false
      let sortInfo=res.data
      //站点Id
      let projectId1=res.data[0].projectId
      let projectId2=res.data[1].projectId
      screenTip.value=res.main[projectId1].projectExtend.screenTip||''
      selfAdd.value=res.main[projectId1].projectExtend.projectAddress||''
      otherAdd.value=res.main[projectId2].projectExtend.projectAddress||''
      screenBottomTip.value=res.main[projectId1].projectExtend.screenBottomTip||''
      maxNumber=0
      sortInfo.forEach(item=>{
        maxNumber+=item.batchDisplayNum
        item.autoTitle = item.showEnd >= item.sortTotalCapacity ? '请进入等候区' : '正排队号段'
        item.color = item.showEnd >= item.sortTotalCapacity ? '#ff0000' : '#ffffff'
      })
      self.value=sortInfo[0]
      other.value=sortInfo[1]
		  }else{
      errMsg.value=res.message
      showStatus=true
    } 	
  }).catch(()=>{
    errMsg.value='请求失败'
    showStatus=true
  })
  timer=setTimeout(getSort,1000)
  onBeforeUnmount(()=>{
    clearTimeout(timer)
  })
}
getSort()
	
//---获取时间
function nextTime() {
  time.value=new Date().Format('hh:mm:ss')
  setTimeout(nextTime, 1000)
}
nextTime()
	
</script>

<style lang="scss" scoped>
	.wrap{
		min-height: 100%;
		background:url('../assets/image/sortBg.jpg') no-repeat;
		background-size: 100% 100%;
		color: #ffffff;
		padding: 0 0.4rem;
	}
	.top{
		text-align: center;
	}
	.top1{
		display: flex;
		align-items: center;
		padding-top: 0.4rem;
		font-size: 0.48rem;
	}
	.top1-left{
		flex-shrink: 0;
		text-align: left;
	}
	.left-number{
		margin-left: 0.2rem;
		font-size: 0.52rem;
	}
	.top1-right{
		flex: 1;
		text-align: left;
		padding-left: 1.6rem;
	}
	.top1-right span:first-child{
		color: #ff0000;
	}
	.top1-right span:nth-child(2){
		color:#faf801;
		font-weight: bold;
	}
	.top1-right span:last-child{
		font-size: 0.4rem;
	}
	.top2{
		font-size: 0.68rem;
		margin-top: 0.3rem;
	}
	.top3{
		margin-top: 0.16rem;
		font-size: 2.16rem;
		color: #ff0000;
		span{
			margin:0 0.6rem;
		}
	}
	.top4{
		display: flex;
		font-size: 0.44rem;
		div{
			display: flex;
			align-items: center;
			justify-content: center;
		}
		span:first-child{
			margin-right: 0.1rem;
		}
		span:last-child{
			font-size: 0.72rem;
		}
	}
	.middle{
		margin-top: 0.2rem;
		div{
			padding:0 0.2rem;
			border:3px solid #e00101;
			text-shadow: 0 0.05rem 0.5rem #8df;
			color: #ffffff;
			font-size: 0.56rem;
			display: flex;
			align-items: center;
		}
	}
	.bottom{
		margin-top: 0.3rem;
	}
	.bottom1{
		display: flex;
		align-items:center;
		justify-content: space-between;
	}
	.bottom1-left{
		font-size: 0.48rem;
		flex: 1;
	}
	.bottom1-left span:first-child{
		color: #ff0000;
	}
	.bottom1-left span:nth-child(2){
		color:#faf801;
		font-weight: bold;
	}
	.bottom1-left span:last-child{
		font-size: 0.4rem;
	}
	.bottom1-right span:first-child{
		font-size: 0.44rem;	
		margin-right: 0.1rem;
	}
	.bottom1-right span:last-child{
		font-size: 0.52rem;
	}
	.bottom2{
		display: flex;
		font-size: 0.44rem;
		margin-top: 0.35rem;
		div{
			display: flex;
			align-items: center;
			justify-content: center;
		}
		span:first-child{
			margin-right: 0.1rem;
		}
		span:last-child{
			font-size: 0.72rem;
		}
	}
	.surplus2{
		width: 30%;
	}
	.surplus2.on{
		width: 50%;
	}
	.bottom3{
		font-size:0.26rem;
		font-weight: bold;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		text-align: center;
		margin-top: 0.06rem;
	}
	.bottom4{
		padding:0 0.2rem;
		position: absolute;
		bottom:10px;
		left:0;
		width: 100%;
		display: flex;
		font-size:20px;
		align-items: center;
		span{
			width: 33.3%;
		}
	}
	
	// 扫码购票
	.scan-code{
		margin-top: 2.8rem;
	}
	.scan-img{
		text-align: center;
		img{
			width:200px;
		}
	}
	.scan-introduce{
		padding: 1.2rem 0 0 1.6rem;
	}
	.scan-phone div{
		margin-top: 0.6rem;
		font-size: 0.4rem;
	}
	@media only screen and (max-height:1000px) {
	  .bottom4{
			position: initial;
			margin-top: 0.2rem;
			padding: 0;
			padding-bottom: 10px;
		}
		.bottom4 span:last-child{
			position: relative;
			left: 0.2rem;
		}
	}
	
</style>